<template>
  <div class="preferences__showroom-config">
    <tabs
      v-if="selectedShowroomSettingsEditorMode === 'Basic'"
      :init-active-tab="activeTab"
      @change-tab="changeActiveTab"
    >
      <template #content>
        <tab
          :is-active="activeTab==='logo'"
          title="Logo"
        >
          <div class="form-group">
            <div class="form-group">
              <text-input
                id="text-input-id-logo-redirect-url"
                v-model:value="logoLink"
                label="Logo redirect URL"
                :max-width="430"
                :min-width="430"
                :name="'logoLink'"
                placeholder="https://..."
                style="max-width:430px"
              />
            </div>
            <div
              class="form-group "
            >
              <upload
                class="logo-upload"
                :company-id="getCompanyId()"
                :file="logo"
                label="Upload logo"
                type="company"
                @uploaded="logoUploaded"
                @remove-file="removeLogo"
              />
            </div>
          </div>
        </tab>
        <tab
          :is-active="activeTab==='colors'"
          title="Colors"
        >
          <div
            v-for="group in basicGrouping"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <template v-if="option.variable === SHOWROOM_DEVICE_THEME">
                  <input-dropdown
                    v-model:value="colors[option.variable]"
                    :options="['light', 'dark']"
                    placeholder="Select device theme"
                    :allow-empty="false"
                    :preselect-first="true"
                    :searchable="false"
                    :show-labels="false"
                    style="height:38px"
                    :track-by="null"
                    :min-width="222"
                    :max-width="222"
                  >
                    <template #single-option="{ option: theme }">
                      {{ theme.charAt(0).toUpperCase() + theme.slice(1) }}
                    </template>
                    <template #option-row="{ option: theme }">
                      {{ theme.charAt(0).toUpperCase() + theme.slice(1) }}
                    </template>
                  </input-dropdown>
                </template>
                <color-picker
                  v-else
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateLinkedVariables(option.linkedVariables, $event)"
                />
              </div>
            </div>
          </div>
        </tab>
      </template>
    </tabs>
    <tabs
      v-else-if="selectedShowroomSettingsEditorMode==='Advanced'"
      :init-active-tab="activeTab"
      @change-tab="changeActiveTab"
    >
      <template #content>
        <tab
          :is-active="activeTab==='logo'"
          title="Logo"
        >
          <div class="form-group">
            <text-input
              id="text-input-id-advanced-logo-link-url"
              v-model:value="logoLink"
              label="Logo redirect URL"
              :max-width="430"
              :min-width="430"
              :name="'logoLink'"
              placeholder="https://..."
              style="max-width:430px"
              :validate="Utils.validateHttpUrl"
              @update:value="updateCurrentHash"
            />
          </div>
          <div class="form-group">
            <upload
              class="logo-upload"
              :company-id="getCompanyId()"
              :file="logo"
              label="Upload logo"
              type="company"
              @uploaded="logoUploaded"
              @remove-file="removeLogo"
            />
          </div>
        </tab>
        <tab
          :is-active="activeTab==='basics'"
          title="Basics"
        >
          <div
            v-for="group in advancedGrouping.filter(g => ['Basic', 'Main', 'Device'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group form-group-inline">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <template v-if="option.variable === SHOWROOM_DEVICE_THEME">
                  <input-dropdown
                    v-model:value="colors[option.variable]"
                    :options="['light', 'dark']"
                    placeholder="Select device theme"
                    :allow-empty="false"
                    :preselect-first="true"
                    :searchable="false"
                    :show-labels="false"
                    style="height:38px"
                    :track-by="null"
                    :min-width="222"
                    :max-width="222"
                  >
                    <template #single-option="{ option: theme }">
                      {{ theme.charAt(0).toUpperCase() + theme.slice(1) }}
                    </template>
                    <template #option-row="{ option: theme }">
                      {{ theme.charAt(0).toUpperCase() + theme.slice(1) }}
                    </template>
                  </input-dropdown>
                </template>
                <color-picker
                  v-else
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateCurrentHash"
                />
              </div>
            </div>
          </div>
        </tab>
        <tab
          :is-active="activeTab==='header'"
          title="Header"
        >
          <div
            v-for="group in advancedGrouping.filter(g => ['Showroom header'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group form-group-inline">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <color-picker
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateCurrentHash"
                />
              </div>
            </div>
          </div>
        </tab>
        <tab
          :is-active="activeTab==='buttons'"
          title="Buttons"
        >
          <div
            v-for="group in advancedGrouping.filter(g => ['Buttons'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group">
              <text-input
                id="text-input-id-custom-m-border-radius"
                :value="colors.buttonMBorderRadius.replace('px', '')"
                label="Border radius"
                :max-width="240"
                type="number"
                :min="0"
                :max="120"
                suffix="px"
                :min-width="240"
                :name="'buttonMBorderRadius'"
                @update:value="updateButtonMBorderRadius"
              />
            </div>
          </div>
          <div
            v-for="group in advancedGrouping.filter(g => ['Primary button', 'Secondary button'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group form-group-inline">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <color-picker
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateCurrentHash"
                />
              </div>
            </div>
          </div>
        </tab>
        <tab
          :is-active="activeTab==='share modal'"
          title="Share modal"
        >
          <div
            v-for="group in advancedGrouping.filter(g => ['Share modal'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group form-group-inline">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <color-picker
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateCurrentHash"
                />
              </div>
            </div>
          </div>
        </tab>
        <tab
          :is-active="activeTab==='sidebars'"
          title="Sidebars"
        >
          <div
            v-for="group in advancedGrouping.filter(g => ['Left sidebar', 'Right sidebar'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group form-group-inline">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <color-picker
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateCurrentHash"
                />
              </div>
            </div>
          </div>
        </tab>
        <tab
          :is-active="activeTab==='badges'"
          title="Badges"
        >
          <div
            v-for="group in advancedGrouping.filter(g => ['Badges'].includes(g.title))"
            :key="group.title"
            class="form-group"
          >
            <heading-with-info
              :text="group.title"
            />
            <div class="form-group form-group-inline">
              <div
                v-for="option in group.options"
                :key="option.variable"
                class="form-group space-between"
                style="max-width:240px"
              >
                <color-picker
                  :id="`${option.label}`"
                  v-model:value="colors[option.variable]"
                  class="color-picker"
                  :label="option.label"
                  placement="top"
                  :preset-colors="presetColors"
                  @update:value="updateCurrentHash"
                />
              </div>
            </div>
          </div>
        </tab>
      </template>
    </tabs>
    <div class="buttons-container">
      <secondary-button
        @click="loadPresets('dark')"
      >
        Dark theme
      </secondary-button>
      <secondary-button
        @click="loadPresets('light')"
      >
        Light theme
      </secondary-button>
      <secondary-button
        v-if="isSuperAdmin"
        @click="loadPresets('wagawin')"
      >
        Wgw theme
      </secondary-button>
      <primary-button
        class="save-button"
        @click="submitShowroomConfig"
      >
        <template v-if="savingState !== 1 && (savingState === 0 || lastSavedStateHash !== currentStateHash)">
          <icon name="save" />
          Save
        </template>
        <template v-if="savingState === 1">
          <div class="saving-dots"></div>
          Saving
        </template>
        <template v-if="savingState === 2 && lastSavedStateHash === currentStateHash">
          <icon name="check" />
          Saved
        </template>
      </primary-button>
    </div>
  </div>
</template>
<script>
import Utils from "../../utils"
import HeadingWithInfo from "@/components/designer/right_pane/HeadingWithInfo.vue"
import ColorPicker from "@/components/designer/element_types/ColorPicker.vue"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import SecondaryButton from "@/components/common/controls/SecondaryButton.vue"
import { advancedGrouping, darkPresets, lightPresets, wagawinPresets, basicGrouping, SHOWROOM_DEVICE_THEME } from "@/classes/showroomConfigManager"
import Upload from "@/components/common/upload/Upload.vue"
import crypto from "crypto-browserify"
import RejectModal from "@/components/common/modal/RejectModal.vue"
import { CompanyRoutes } from "@/api/routes"
import TextInput from "@/components/common/form/TextInput.vue"
import InputDropdown from "@/components/common/dropdown/InputDropdown.vue"
export default {
  components: {
    TextInput,
    Upload,
    SecondaryButton,
    ColorPicker,
    HeadingWithInfo,
    InputDropdown
  },
  data () {
    return {
      activeTab: "logo",
      colors: {},
      logoLink: "",
      logo: null,
      currentStateHash: null,
      lastSavedStateHash: null,
      savingState: 0
    }
  },
  computed: {
    ...mapGetters({ presetColors: "getPresetColors" }),
    ...mapState(["selectedShowroomSettingsEditorMode", "currentUser"]),
    Utils () {
      return Utils
    },
    isSuperAdmin () {
      return this.currentUser && this.currentUser.roles.super_admin
    },
    advancedGrouping () {
      return advancedGrouping
    },
    basicGrouping () {
      return basicGrouping
    },
    SHOWROOM_DEVICE_THEME () {
      return SHOWROOM_DEVICE_THEME
    }
  },
  watch: {
    selectedShowroomSettingsEditorMode () {
      this.activeTab = "logo"
    }
  },
  created () {
    this.loadCurrentUser().then(() => {
      this.$apiClient.get(CompanyRoutes.getShowroomSettings()).then(response => {
        const { showroomSettings } = response.data.data
        this.colors = showroomSettings.colors
        this.logoLink = showroomSettings.logoLink
        this.logo = showroomSettings?.logo.id ? showroomSettings.logo : null
        this.setSelectedShowroomSettingsEditorMode(showroomSettings.mode)
      })
    })
  },
  mounted () {
    document.querySelector("#app .main").addEventListener("click", this.emitClickedBody, { passive: true })
  },
  beforeUnmount () {
    document.querySelector("#app .main").removeEventListener("click", this.emitClickedBody, { passive: true })
  },
  methods: {
    ...mapActions(["loadCurrentUser"]),
    ...mapMutations(["setSelectedShowroomSettingsEditorMode"]),
    emitClickedBody (event) {
      this.$eventHub.$emit("clicked-body", event)
    },
    updateButtonMBorderRadius (val) {
      this.colors.buttonMBorderRadius = `${val}px`
      this.colors.buttonRefreshBorderRadius = `${val}px`
      this.colors.buttonRefreshBorderRadiusHover = `${val}px`

      this.updateCurrentHash()
    },
    removeLogo () {
      this.logo = null

      this.updateCurrentHash()
    },
    loadPresets (theme) {
      const continueFunction = ()=>{
        this.resetStyles(theme)
      }
      this.$modal.show(RejectModal, {
        title: "Caution",
        description: "Loading a preset will overwrite your current settings. Remember to save the settings afterward.",
        actionButton: "Load preset",
        successMessage: "",
        errorMessage: "",
        afterApproval: continueFunction
      })
    },
    updateCurrentHash () {
      const showroomSettings = {
        colors: Utils.cloneDeep(this.colors),
        logoLink: this.logoLink,
        logoId: this.logo ? this.logo.id : null
      }

      this.currentStateHash = crypto.createHash("md5").update(JSON.stringify(Utils.cloneDeep(showroomSettings))).digest("hex")
    },
    updateLinkedVariables (linkedVariables = [], value) {
      linkedVariables.forEach(variable => {
        this.colors[variable] = value
      })

      this.updateCurrentHash()
    },
    resetStyles (theme) {
      const themes = {
        wagawin: wagawinPresets,
        dark: darkPresets,
        light: lightPresets
      }
      this.colors = Utils.cloneDeep(themes[theme])

      this.updateCurrentHash()
    },
    logoUploaded (logo) {
      this.logo = logo

      this.updateCurrentHash()
    },
    getCompanyId () {
      return Number(Utils.getCookie("company-id"))
    },
    changeActiveTab (tab) {
      this.activeTab = tab.title.toLowerCase()
    },
    isValidLogoLinkUrl () {
      if (this.logoLink.length === 0) {
        return true
      }

      return Utils.validateHttpUrl(this.logoLink)
    },
    submitShowroomConfig () {
      this.savingState = 1

      if (!this.isValidLogoLinkUrl()) {
        this.$toasted.error("Logo redirect URL should be a valid HTTPS URL, e.g. https://adtron.io.")
        this.savingState = 0
        return
      }

      const unifiedShowroomSettingsState = {
        colors: Utils.cloneDeep(this.colors),
        logoLink: this.logoLink,
        logoId: this.logo ? this.logo.id : null
      }

      const showroomSettingsDto = {
        ...unifiedShowroomSettingsState,
        mode: this.selectedShowroomSettingsEditorMode
      }

      return this.$apiClient.put(CompanyRoutes.updateShowroomSettings(), { showroomSettings: showroomSettingsDto }).then(() => {
        this.lastSavedStateHash = crypto.createHash("md5").update(JSON.stringify(Utils.cloneDeep(unifiedShowroomSettingsState))).digest("hex")
        if (this.currentStateHash === null) {
          this.currentStateHash = this.lastSavedStateHash
        }
        this.savingState = 2
      }, () => {
        this.savingState = 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.preferences .preferences__showroom-config  {
  padding-left: 1.875rem;

  .logo-upload {
    width: 430px;
  }

  ::v-deep(input::-webkit-outer-spin-button),
  ::v-deep(input::-webkit-inner-spin-button) {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  ::v-deep(input[type=number]) {
    -moz-appearance: textfield;
  }

  .buttons-container {
    position:absolute; right:0;top:0; display:flex; column-gap: 12px;

    .save-button {
     width:100px;
    }
  }
}
</style>
