<template>
  <base-modal
    id="packageDownloadModal"
    ref="modal"
    class="modal-auto-height delete-modal text-left"
    :show-close-button="true"
    size="small"
    title="Download a package"
  >
    <template #content>
      <div>
        <div class="form-group">
          <input-dropdown
            id="packages"
            v-model:value="packageLink"
            label="Package"
            name="job"
            :options="orderedOptions"
            track-by="value"
          />
        </div>

        <div class="form-group">
          <div>
            <label class="input-label">
              <span>Share link</span>
            </label>
            <base-input
              id="shareLink"
              ref="shareLink"
              placeholder="http://example.com"
              :readonly="true"
              :value="packageLinkValue"
              @click="selectShareLink"
            />
            <div class="copy-icon">
              <icon
                name="copy_alt"
                @click="copyLink('shareLink')"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <base-button
        :min-width="114"
        size="m"
        @click.prevent="modal.close"
      >
        Cancel
      </base-button>
      <primary-button
        :disabled="!packageLinkValue"
        :min-width="114"
        size="m"
        @click.prevent="download"
      >
        Download
      </primary-button>
    </template>
  </base-modal>
</template>

<script>
import { mapState } from "vuex"
import Language from "../../i18n/en"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

export default {
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      modal: {
        close: () => {}
      },
      packageLink: null
    }
  },

  computed: {
    packageLinkValue () {
      return this.packageLink?.value || ""
    },

    options () {
      const links = this.links?.length ? this.links : this.activeDesign?.download_links || []
      return links.map(link => ({
        name: this.extractLastLinkPart(link),
        value: link,
        date: this.extractDateFromLink(link)
      }))
    },

    orderedOptions () {
      return [...this.options].sort((a, b) => {
        return b.date.diff(a.date)
      })
    },

    ...mapState(["activeDesign"])
  },

  created () {
    dayjs.extend(utc)
  },

  mounted () {
    this.modal = this.$refs.modal
  },

  methods: {
    extractDateFromLink (link) {
      const dateParts = link.split("/").pop().split("_").splice(-6, 6)
      return dayjs.utc(dateParts.join(" "), "YYYY MM DD HH mm ss")
    },

    extractLastLinkPart (link) {
      const filename = link.split("/").pop().replace(".zip", "")
      return this.parseDateInLink(filename)
    },

    formatDownloadPackageDate (date) {
      return dayjs.utc(date, "YYYY MM DD HH mm ss")
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
    },

    parseDateInLink (link) {
      const parts = link.split("_")
      const datePart = parts.splice(-6, 6).join(" ")
      const name = parts.join("_")
      return `${name} (${this.formatDownloadPackageDate(datePart)})`
    },

    selectShareLink () {
      this.$refs.shareLink.$el.querySelector("input").select()
    },

    copyLink (ref) {
      const input = this.$refs[ref].$el.querySelector("input")
      input.select()
      document.execCommand("copy")
      input.selectionStart = input.selectionEnd = 0
      input.blur()
      this.$toasted.success(Language.clipboard.success.copy_link)
    },

    download () {
      window.open(this.packageLink.value)
      this.modal.close()
    }
  }
}
</script>
