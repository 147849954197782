<template>
  <div
    ref="animation"
    :style="style"
  ></div>
</template>
<script>
import lottie from "lottie-web"

export default {
  props: {
    loop: {
      type: [Boolean, Number],
      default: false
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    renderer: {
      type: String,
      default: "svg"
    },
    height: {
      type: String,
      default: "100%"
    },
    width: {
      type: String,
      default: "100%"
    }
  },
  data () {
    return {
      anim: null
    }
  },
  computed: {
    style () {
      return {
        width: this.width,
        height: this.height
      }
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
    if (this.anim) {
      this.anim.destroy()
    }
  },
  methods: {
    init () {
      const settings = {
        container: this.$refs.animation,
        renderer: this.renderer,
        loop: this.loop,
        autoplay: this.autoPlay,
        animationData: require("../lottie/loadingStatus.json")
      }
      this.anim = lottie.loadAnimation(settings)
    }
  }
}
</script>
