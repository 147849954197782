<template>
  <div>
    <label
      v-if="label"
      class="input-label"
      :for="inputId"
    >
      <span>
        {{ label }}
        <icon
          v-if="informationTooltip"
          class="info-tooltip"
          name="info-02"
          :tooltip="informationTooltip"
          :tooltip-max-width="220"
          tooltip-placement="right-start"
        />
      </span>
    </label>
    <div
      class="base-input"
      :class="[
        inputActive && isInputInvalid === false ? 'active' : '',
        disabled ? 'disabled' : '',
        required ? 'required' : '',
        isInputInvalid ? 'error' : '',
      ]"
      :style="getContainerStyle()"
      @click="baseInputClick"
    >
      <textarea
        :id="labelId"
        :ref="'textarea-' + labelId"
        :data-radius="borderRadius + 'px'"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :style="getInputStyle()"
        :value="value"
        @blur="blurEvent"
        @change="changeEvent"
        @focus="focusEvent"
        @input="inputEvent"
        @keydown="keyDownEvent"
        @keyup="keyUpEvent"
      ></textarea>
      <icon
        v-if="allowMacro"
        class="textarea-macro-plus"
        name="add"
        tooltip="Add data-binding"
        tooltip-placement="right-start"
        @mousedown.prevent
        @click.prevent.stop="toggleMacroDropdown"
      />
      <label
        v-if="placeholder && (typeof value !== 'undefined' && (typeof value === 'string' && value.length === 0))"
        class="placeholder"
        :for="id ? null : labelId"
        :style="getPlaceholderStyle()"
      >{{ placeholder }}</label>
      <macro-hint
        v-if="displayMacroHint && availableMacros.length > 0"
        :macros="availableMacros"
        @cancel="cancelMacro"
        @input="chooseBinding"
        @key="filterBinding"
      />
    </div>
  </div>
</template>

<script>
import MacroHint from "../macro/MacroHint"
import { mapGetters, mapState } from "vuex"
import {
  CounterModuleType,
  PollModuleType,
  SurveyModuleType,
  TimerModuleType
} from "../../designer/module_types/types"

import { v4 as uuidv4 } from "uuid"
import Utils from "@/utils"

export default {
  components: {
    MacroHint
  },
  props: {
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    informationTooltip: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 60
    },
    focusHeight: {
      type: Number,
      default: 60
    },
    borderRadius: {
      type: Number,
      default: 8
    },
    fontSize: {
      type: Number,
      default: 16
    },
    paddingX: {
      type: Number,
      default: 20
    },
    paddingY: {
      type: Number,
      default: 12
    },
    minWidth: {
      type: Number,
      default: 260
    },
    maxWidth: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: null
    },
    allowMacro: {
      type: Boolean,
      default: false
    },
    allowEnter: {
      type: Boolean,
      default: false
    }
  },
  emits: ["active", "macroToggleClicked", "input", "keyup", "change", "blur", "focus", "update:value"],
  data () {
    return {
      content: "",
      inputActiveValue: false,
      isInvalid: false,
      placeholderVisible: true,
      displayMacroHint: false,
      searchPhrase: ""
    }
  },
  computed: {
    counterModules () {
      return this.allModules.filter(m => m.type === CounterModuleType)
    },
    inputActive: {
      get () {
        return this.inputActiveValue || this.active
      },
      set (val) {
        this.$emit("active", val)
        this.inputActiveValue = val
      }
    },
    isInputInvalid () {
      return this.error || this.isInvalid
    },
    availableMacros () {
      const availableMacros = []

      if (this.counterModules.length) {
        this.counterModules.forEach(m => {
          const counterVariableName = Utils.getModuleDataValue(m, "variableName", null)
          if (counterVariableName) {
            availableMacros.push({
              description: counterVariableName,
              macro: counterVariableName,
              replacement: counterVariableName
            })
          }
        })

      }
      if (this.selectedModule) {
        const module = this.modules.find(m => m.uuid === this.selectedModule)
        const parentModule = this.modules.find(m => m.uuid === module?.parentModuleId)
        if (parentModule?.type === PollModuleType) {
          const pollMacros = [
            {
              description: "Result right",
              macro: "resultright",
              replacement: "Result right"
            },
            {
              description: "Result left",
              macro: "resultleft",
              replacement: "Result left"
            }
          ]
          pollMacros.forEach(m => availableMacros.push(m))
        }
        if (parentModule?.type === SurveyModuleType) {
          const surveyMacros = [
            {
              description: "Vote count right",
              macro: "votecountright",
              replacement: "Vote count right"
            },
            {
              description: "Vote count center",
              macro: "votecountcenter",
              replacement: "Vote count center"
            },
            {
              description: "Vote count left",
              macro: "votecountleft",
              replacement: "Vote count left"
            },
            {
              description: "Vote percentage right",
              macro: "votepercentageright",
              replacement: "Vote percentage right"
            },
            {
              description: "Vote percentage center",
              macro: "votepercentagecenter",
              replacement: "Vote percentage center"
            },
            {
              description: "Vote percentage left",
              macro: "votepercentageleft",
              replacement: "Vote percentage left"
            }
          ]
          surveyMacros.forEach(m => availableMacros.push(m))
        }
        if (parentModule?.type === TimerModuleType) {
          const timerMacros = [
            {
              description: "Seconds (0)",
              macro: "s",
              replacement: "Seconds (0)"
            },
            {
              description: "Seconds (00)",
              macro: "ss",
              replacement: "Seconds (00)"
            },
            {
              description: "Minutes (0)",
              macro: "m",
              replacement: "Minutes (0)"
            },
            {
              description: "Minutes (00)",
              macro: "mm",
              replacement: "Minutes (00)"
            }
          ]
          timerMacros.forEach(m => availableMacros.push(m))
        }
      }

      const customDataBinding = [].concat(...[].concat(this.dataBinding.map(b => b.variables))).map((b) => {
        return {
          description: b,
          macro: b,
          replacement: b
        }
      })

      customDataBinding.forEach(m => availableMacros.push(m))

      const searchPhrase = this.searchPhrase.toLowerCase()
      if (searchPhrase.length === 0) {
        return availableMacros
      }

      return availableMacros.filter(m => m.macro.startsWith(searchPhrase) || m.replacement.toLowerCase().startsWith(searchPhrase))
    },
    ...mapState(["dataBinding", "selectedModule"]),
    ...mapGetters({ modules: "getModules", allModules: "getAllModules" })
  },
  beforeMount () {
    this.labelId = this.id ? this.id : uuidv4()
  },
  created () {
    this.inputId = uuidv4()
    if (this.id !== null) {
      this.inputId = this.id
    }
  },
  mounted () {
    this.$eventHub.$on("clicked-body", this.bodyClicked)
    this.placeholderVisible = this.value && this.value.length === 0
  },
  beforeUnmount () {
    this.$eventHub.$off("clicked-body", this.bodyClicked)
  },
  methods: {
    toggleMacroDropdown () {
      this.$emit("macroToggleClicked")
      this.displayMacroHint = !this.displayMacroHint
    },
    bodyClicked () {
      this.displayMacroHint = false
    },
    baseInputClick (e) {
      if (this.allowMacro === false && this.displayMacroHint === false) {
        e.preventDefault()
      }
      this.placeholderVisible = false
    },
    filterBinding (e) {
      if (e.which === 8) {
        if (this.searchPhrase.length > 0) {
          this.searchPhrase = this.searchPhrase.substr(0, this.searchPhrase.length - 1)
        }
        return
      } else if ((e.which < 65 && e.which !== 32 && e.which !== 8) || e.which > 90) {
        return false
      }

      this.searchPhrase += e.key
    },
    cancelMacro () {
      this.searchPhrase = ""
      this.displayMacroHint = false
    },
    chooseBinding (value) {
      const caretIndex = this.$refs["textarea-" + this.labelId].selectionStart
      const beforeCaretText = this.value !== null ? this.value.slice(0, caretIndex) : ""
      const lastDataBindingIndex = beforeCaretText.lastIndexOf("%%")
      if (beforeCaretText.endsWith("%%" + value.macro + "%%")) {
        this.cancelMacro()
        return
      }
      let sliceEnd
      if (lastDataBindingIndex === -1 || beforeCaretText.split("%%").length % 2 !== 0) {
        sliceEnd = beforeCaretText.length
      } else {
        sliceEnd = beforeCaretText.lastIndexOf("%%")
      }
      let newContent = beforeCaretText.slice(0, sliceEnd) + "%%" + value.macro + "%%"

      const newCaretPosition = newContent.length

      setTimeout(() => {
        this.$refs["textarea-" + this.labelId].setSelectionRange(newCaretPosition, newCaretPosition)
      }, 100)

      newContent += this.value !== null ? this.value.substr(caretIndex) : ""

      this.cancelMacro()
      this.$emit("input", newContent)
      this.$emit("update:value", newContent)
    },
    keyDownEvent (e) {
      const enter = (this.allowEnter === false && e.key === "Enter")
      if (enter || (this.displayMacroHint && ["ArrowUp", "ArrowDown"].includes(e.key)) || (e.key === "Enter" && this.displayMacroHint)) {
        e.preventDefault()
      }
    },
    keyUpEvent (e) {
      this.$emit("keyup", e)
      if (this.allowEnter === false && (e.key === "Enter" || e.key === "Escape")) {
        this.inputActive = false
        e.target.blur()
      }
      if (this.allowMacro) {
        const caretIndex = this.$refs["textarea-" + this.labelId].selectionStart
        const beforeCaretText = this.value.slice(0, caretIndex)
        if (this.displayMacroHint) {
          if (e.key === "Escape") {
            this.cancelMacro()
          } else {
            this.displayMacroHint = this.availableMacros.length > 0
            if (this.displayMacroHint === false) {
              this.searchPhrase = ""
            }
          }
        } else if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Enter", "Backspace", "Space"].includes(e.key) === false) {
          const percentageSignSplit = beforeCaretText.split("%%").length
          this.displayMacroHint = this.value.endsWith("%%") || (beforeCaretText.endsWith("%%") && percentageSignSplit > 1 && percentageSignSplit % 2 === 0)
        }
      }
    },
    changeEvent (e) {
      this.isInvalid = this.required && e.target.value.length === 0
      const value = this.separators ? e.target.value.replace(/\s|,|\./g, "") : e.target.value
      this.$emit("change", value, e)
    },
    blurEvent (e) {
      if (this.displayMacroHint) {
        e.preventDefault()
      } else {
        this.inputActive = false
        this.displayMacroHint = false
      }
      this.$emit("blur", e)
    },
    focusEvent (e) {
      this.inputActive = true
      this.$emit("focus", e)
    },
    inputEvent (e) {
      this.isInvalid = this.required && e.target.value.length === 0
      const value = this.separators ? e.target.value.replace(/\s|,|\./g, "") : e.target.value
      this.$emit("input", value, e)
      this.$emit("update:value", value, e)
    },
    getContainerStyle () {
      const styles = {
        minWidth: this.minWidth + "px",
        maxWidth: this.maxWidth ? this.maxWidth + "px" : null
      }

      if (this.inputActive && this.focusHeight) {
        styles.height = styles["min-height"] = this.focusHeight + "px"
      } else if (this.height !== null) {
        styles.height = this.height + "px"
      }

      return styles
    },
    getInputStyle () {
      const styles = {
        borderRadius: this.borderRadius + "px",
        fontSize: this.fontSize + "px",
        padding: [this.paddingY + "px", this.paddingX + "px", this.paddingY + "px", (this.paddingX) + "px"].join(" ")
      }

      if (this.inputActive && this.focusHeight) {
        styles["min-height"] = this.focusHeight + "px"
      }

      return styles
    },
    getPlaceholderStyle () {
      return {
        top: this.paddingY + "px",
        left: this.paddingX + "px",
        fontSize: this.fontSize + "px"
      }
    }
  }
}
</script>
