const PRIMARY_TEXT_COLOR = "primaryTextColor"
const SECONDARY_TEXT_COLOR = "secondaryTextColor"
const TERTIARY_TEXT_COLOR = "tertiaryTextColor"
const ACCENT_COLOR = "accentColor"
const ACCENT_COLOR_SECONDARY = "accentColorSecondary"
const HEADLINE_COLOR = "headlineColor"
const SHOWROOM_HEADER_BACKGROUND_COLOR = "showroomHeaderBackgroundColor"
const SHOWROOM_HEADER_ICON_COLOR = "showroomHeaderIconColor"
const SHOWROOM_HEADER_ICON_COLOR_HOVER = "showroomHeaderIconColorHover"
const SVG_STROKE_COLOR = "svgStrokeColor"
const SVG_STROKE_COLOR_HOVER = "svgStrokeColorHover"
const PRIMARY_BUTTON_BACKGROUND_DEFAULT = "primaryButtonBackgroundDefault"
const PRIMARY_BUTTON_BACKGROUND_HOVER = "primaryButtonBackgroundHover"
const PRIMARY_BUTTON_BACKGROUND_PRESSED = "primaryButtonBackgroundPressed"
const PRIMARY_BUTTON_TEXT_COLOR = "primaryButtonTextColor"
const PRIMARY_BUTTON_TEXT_COLOR_HOVER = "primaryButtonTextColorHover"
const PRIMARY_BUTTON_TEXT_COLOR_PRESSED = "primaryButtonTextColorPressed"
const BUTTON_M_BORDER_RADIUS = "buttonMBorderRadius"
const BUTTON_M_BORDER_RADIUS_HOVER = "buttonMBorderRadiusHover"
const SHOWROOM_MAIN_BACKGROUND_COLOR = "showroomMainBackgroundColor"
const DEVICE_SCREEN_BACKGROUND = "deviceScreenBackground"
const BUTTON_REFRESH_BORDER_RADIUS = "buttonRefreshBorderRadius"
const BUTTON_REFRESH_BORDER_RADIUS_HOVER = "buttonRefreshBorderRadiusHover"
const SECONDARY_BUTTON_BACKGROUND_DEFAULT = "secondaryButtonBackgroundDefault"
const SECONDARY_BUTTON_BACKGROUND_HOVER = "secondaryButtonBackgroundHover"
const SECONDARY_BUTTON_BACKGROUND_PRESSED = "secondaryButtonBackgroundPressed"
const SECONDARY_BUTTON_BACKGROUND_DISABLED = "secondaryButtonBackgroundDisabled"
const SECONDARY_BUTTON_COLOR_DEFAULT = "secondaryButtonColorDefault"
const SECONDARY_BUTTON_COLOR_ACTIVE = "secondaryButtonColorActive"
const SECONDARY_BUTTON_COLOR_TEXT_PRESSED = "secondaryButtonColorTextPressed"
const SECONDARY_BUTTON_SHADOW_COLOR = "secondaryButtonShadowColor"
const TABS_HEADER_TEXT_COLOR = "tabsHeaderTextColor"
const TABS_HEADER_TEXT_COLOR_ACTIVE = "tabsHeaderTextColorActive"
const CARD_BACKGROUND_COLOR_HOVER = "cardBackgroundColorHover"
const DISPLAY_VARIANTS_COLOR = "displayVariantsColor"
const DISPLAY_VARIANTS_COLOR_ACTIVE = "displayVariantsColorActive"
const DISPLAY_VARIANTS_COLOR_DISABLED = "displayVariantsColorDisabled"
const DISPLAY_VARIANTS_BACKGROUND_ACTIVE = "displayVariantsBackgroundActive"
const COLOR_PICKER_BACKGROUND_COLOR = "colorPickerBackgroundColor"
const COLOR_PICKER_BORDER_COLOR = "colorPickerBorderColor"
const SHOWROOM_ACTIVE_OPTION_BACKGROUND_COLOR = "showroomActiveOptionBackgroundColor"
const SHOWROOM_ACTIVE_OPTION_COLOR = "showroomActiveOptionColor"
const MODAL_BACKGROUND_COLOR = "modalBackgroundColor"
const BASE_INPUT_PLACEHOLDER = "baseInputPlaceholder"
const LABEL_TEXT_COLOR = "labelTextColor"
const INPUT_GLOW_COLOR = "inputGlowColor"
const BASE_INPUT_BACKGROUND = "baseInputBackground"
const BASE_INPUT_COLOR = "baseInputColor"
const SHOWROOM_LEFT_SIDEBAR_BACKGROUND_COLOR = "showroomLeftSidebarBackgroundColor"
const SHOWROOM_RIGHT_SIDEBAR_BACKGROUND_COLOR = "showroomRightSidebarBackgroundColor"
const SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DESIGN = "showroomCreativeStatusBadgeBackgroundColorDesign"
const SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DESIGN = "showroomCreativeStatusBadgeTextColorDesign"
const SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_LIVE = "showroomCreativeStatusBadgeBackgroundColorLive"
const SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_LIVE = "showroomCreativeStatusBadgeTextColorLive"
const SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DELIVERED = "showroomCreativeStatusBadgeBackgroundColorDelivered"
const SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DELIVERED = "showroomCreativeStatusBadgeTextColorDelivered"
const SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_REVIEW = "showroomCreativeStatusBadgeBackgroundColorReview"
const SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_REVIEW = "showroomCreativeStatusBadgeTextColorReview"
export const SHOWROOM_DEVICE_THEME = "showroomDeviceTheme"

export const basicGrouping = [
  {
    title: "Basic",
    options: [
      {
        label: "Primary text",
        variable: PRIMARY_TEXT_COLOR,
        linkedVariables: [
          HEADLINE_COLOR,
          SECONDARY_BUTTON_COLOR_DEFAULT,
          SECONDARY_BUTTON_SHADOW_COLOR,
          SVG_STROKE_COLOR_HOVER,
          SHOWROOM_ACTIVE_OPTION_COLOR,
          DISPLAY_VARIANTS_COLOR_ACTIVE,
          BASE_INPUT_COLOR,
          BASE_INPUT_PLACEHOLDER,
          PRIMARY_BUTTON_TEXT_COLOR
        ]
      },
      {
        label: "Secondary text",
        variable: SECONDARY_TEXT_COLOR,
        linkedVariables: [
          SVG_STROKE_COLOR,
          LABEL_TEXT_COLOR,
          BASE_INPUT_BACKGROUND
        ]
      },
      {
        label: "Accent",
        variable: ACCENT_COLOR,
        linkedVariables: [
          INPUT_GLOW_COLOR,
          SHOWROOM_ACTIVE_OPTION_BACKGROUND_COLOR,
          DISPLAY_VARIANTS_BACKGROUND_ACTIVE
        ]
      },
      {
        label: "Accent secondary",
        variable: ACCENT_COLOR_SECONDARY
      }
    ]
  },
  {
    title: "Main, header, sidebars",
    options: [
      {
        label: "Main background",
        variable: SHOWROOM_MAIN_BACKGROUND_COLOR,
        linkedVariables: [DEVICE_SCREEN_BACKGROUND]
      },
      {
        label: "Header and sidebars background",
        variable: SHOWROOM_LEFT_SIDEBAR_BACKGROUND_COLOR,
        linkedVariables: [MODAL_BACKGROUND_COLOR, SHOWROOM_HEADER_BACKGROUND_COLOR, SHOWROOM_RIGHT_SIDEBAR_BACKGROUND_COLOR]
      }
    ]
  },
  {
    title: "Device theme",
    options: [
      {
        label: "Phone mockup colors",
        variable: SHOWROOM_DEVICE_THEME,
        linkedVariables: [DEVICE_SCREEN_BACKGROUND]
      }
    ]
  }
]

export const advancedGrouping = [
  {
    title: "Basic",
    options: [
      {
        label: "Primary text",
        variable: PRIMARY_TEXT_COLOR
      },
      {
        label: "Secondary text",
        variable: SECONDARY_TEXT_COLOR
      },
      {
        label: "Headline",
        variable: HEADLINE_COLOR
      },
      {
        label: "Accent",
        variable: ACCENT_COLOR
      },
      {
        label: "Accent secondary",
        variable: ACCENT_COLOR_SECONDARY
      }
    ]
  },
  {
    title: "Showroom header",
    options: [
      {
        label: "Background",
        variable: SHOWROOM_HEADER_BACKGROUND_COLOR
      },
      {
        label: "Icon",
        variable: SHOWROOM_HEADER_ICON_COLOR
      },
      {
        label: "Icon (hover)",
        variable: SHOWROOM_HEADER_ICON_COLOR_HOVER
      }
    ]
  },
  {
    title: "Buttons",
    options: [
      {
        label: "Border radius",
        variable: BUTTON_M_BORDER_RADIUS
      }
    ]
  },
  {
    title: "Primary button",
    options: [
      {
        label: "Text",
        variable: PRIMARY_BUTTON_TEXT_COLOR
      },
      {
        label: "Text (hover)",
        variable: PRIMARY_BUTTON_TEXT_COLOR_HOVER
      },
      {
        label: "Text (pressed)",
        variable: PRIMARY_BUTTON_TEXT_COLOR_PRESSED
      },
      {
        label: "Background",
        variable: PRIMARY_BUTTON_BACKGROUND_DEFAULT
      },
      {
        label: "Background (hover)",
        variable: PRIMARY_BUTTON_BACKGROUND_HOVER
      },
      {
        label: "Background (pressed)",
        variable: PRIMARY_BUTTON_BACKGROUND_PRESSED
      }
    ]
  },
  {
    title: "Main",
    options: [
      {
        label: "Device background",
        variable: SHOWROOM_MAIN_BACKGROUND_COLOR
      },
      {
        label: "Device screen background",
        variable: DEVICE_SCREEN_BACKGROUND
      }
    ]
  },
  {
    title: "Device theme",
    options: [
      {
        label: "Device theme",
        variable: SHOWROOM_DEVICE_THEME
      }
    ]
  },
  {
    title: "Secondary button",
    options: [
      {
        label: "Background",
        variable: SECONDARY_BUTTON_BACKGROUND_DEFAULT
      },
      {
        label: "Background (hover)",
        variable: SECONDARY_BUTTON_BACKGROUND_HOVER
      },
      {
        label: "Background (pressed)",
        variable: SECONDARY_BUTTON_BACKGROUND_PRESSED
      },
      {
        label: "Text",
        variable: SECONDARY_BUTTON_COLOR_DEFAULT
      },
      {
        label: "Text (hover)",
        variable: SECONDARY_BUTTON_COLOR_ACTIVE
      },
      {
        label: "Text (pressed)",
        variable: SECONDARY_BUTTON_COLOR_TEXT_PRESSED
      },
      {
        label: "Button outline",
        variable: SECONDARY_BUTTON_SHADOW_COLOR
      }
    ]
  },
  {
    title: "Share modal",
    options: [
      {
        label: "Background",
        variable: MODAL_BACKGROUND_COLOR
      },
      {
        label: "Input label",
        variable: LABEL_TEXT_COLOR
      },
      {
        label: "Input background",
        variable: BASE_INPUT_BACKGROUND
      },
      {
        label: "Input text",
        variable: BASE_INPUT_COLOR
      },
      {
        label: "Input placeholder",
        variable: BASE_INPUT_PLACEHOLDER
      },
      {
        label: "Input outline",
        variable: INPUT_GLOW_COLOR
      }
    ]
  },
  {
    title: "Left sidebar",
    options: [
      {
        label: "Background",
        variable: SHOWROOM_LEFT_SIDEBAR_BACKGROUND_COLOR
      }
    ]
  },
  {
    title: "Right sidebar",
    options: [
      {
        label: "Background",
        variable: SHOWROOM_RIGHT_SIDEBAR_BACKGROUND_COLOR
      },
      {
        label: "Tabs header text",
        variable: TABS_HEADER_TEXT_COLOR
      },
      {
        label: "Tabs header text (active)",
        variable: TABS_HEADER_TEXT_COLOR_ACTIVE
      },
      {
        label: "Preview type text",
        variable: DISPLAY_VARIANTS_COLOR
      },
      {
        label: "Preview type (active)",
        variable: DISPLAY_VARIANTS_COLOR_ACTIVE
      },
      {
        label: "Preview type background",
        variable: DISPLAY_VARIANTS_BACKGROUND_ACTIVE
      },
      {
        label: "Showroom active option background",
        variable: SHOWROOM_ACTIVE_OPTION_BACKGROUND_COLOR
      },
      {
        label: "Showroom active option text",
        variable: SHOWROOM_ACTIVE_OPTION_COLOR
      }
    ]
  },
  {
    title: "Badges",
    options: [
      {
        label: "Design status background",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DESIGN
      },
      {
        label: "Design status text",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DESIGN
      },
      {
        label: "Live status background",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_LIVE
      },
      {
        label: "Live status text",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_LIVE
      },
      {
        label: "Delivered status background",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DELIVERED
      },
      {
        label: "Delivered status text",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DELIVERED
      },
      {
        label: "Review status background",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_REVIEW
      },
      {
        label: "Review status text",
        variable: SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_REVIEW
      }
    ]
  }
]

export const wagawinPresets = {
  [PRIMARY_TEXT_COLOR]: "#000000FF",
  [SECONDARY_TEXT_COLOR]: "#84878BFF",
  [TERTIARY_TEXT_COLOR]: "#61676e",
  [ACCENT_COLOR]: "#D3185CFF",
  [ACCENT_COLOR_SECONDARY]: "#D3185CFF",
  [HEADLINE_COLOR]: "#000000FF",
  [SHOWROOM_HEADER_BACKGROUND_COLOR]: "#FAFBFBFF",
  [SHOWROOM_HEADER_ICON_COLOR]: "#000000FF",
  [SHOWROOM_HEADER_ICON_COLOR_HOVER]: "#D3185CFF",
  [SVG_STROKE_COLOR]: "#84878BFF",
  [SVG_STROKE_COLOR_HOVER]: "#000050FF",
  [PRIMARY_BUTTON_TEXT_COLOR]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR_HOVER]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR_PRESSED]: "#ffffff",
  [PRIMARY_BUTTON_BACKGROUND_DEFAULT]: "#FD0668FF",
  [PRIMARY_BUTTON_BACKGROUND_HOVER]: "#FD17ABFF",
  [PRIMARY_BUTTON_BACKGROUND_PRESSED]: "#FD0668FF",
  [BUTTON_M_BORDER_RADIUS]: "3px",
  [BUTTON_M_BORDER_RADIUS_HOVER]: "3px",
  [SHOWROOM_MAIN_BACKGROUND_COLOR]: "#FFFFFFFF",
  [DEVICE_SCREEN_BACKGROUND]: "#FFFFFFFF",
  [BUTTON_REFRESH_BORDER_RADIUS]: "3px",
  [BUTTON_REFRESH_BORDER_RADIUS_HOVER]: "3px",
  [SECONDARY_BUTTON_BACKGROUND_DEFAULT]: "#FFFFFFFF",
  [SECONDARY_BUTTON_BACKGROUND_HOVER]: "#000000FF",
  [SECONDARY_BUTTON_BACKGROUND_PRESSED]: "rgba(132, 135, 139, 0.52)",
  [SECONDARY_BUTTON_BACKGROUND_DISABLED]: "rgba(132, 135, 139, 0.08)",
  [SECONDARY_BUTTON_COLOR_DEFAULT]: "#000000FF",
  [SECONDARY_BUTTON_COLOR_ACTIVE]: "#ffffff",
  [SECONDARY_BUTTON_SHADOW_COLOR]: "#000050FF",
  [TABS_HEADER_TEXT_COLOR]: "#61676e",
  [TABS_HEADER_TEXT_COLOR_ACTIVE]: "#ffffff",
  [CARD_BACKGROUND_COLOR_HOVER]: "#222529",
  [DISPLAY_VARIANTS_COLOR]: "#61676EFF",
  [DISPLAY_VARIANTS_COLOR_ACTIVE]: "#FFFFFFFF",
  [DISPLAY_VARIANTS_COLOR_DISABLED]: "#DEE2E6FF",
  [DISPLAY_VARIANTS_BACKGROUND_ACTIVE]: "#5ABFC1FF",
  [COLOR_PICKER_BACKGROUND_COLOR]: "#292c31",
  [COLOR_PICKER_BORDER_COLOR]: "#222529FF",
  [SHOWROOM_ACTIVE_OPTION_BACKGROUND_COLOR]: "#D3185CFF",
  [SHOWROOM_ACTIVE_OPTION_COLOR]: "#FFFFFFFF",
  [MODAL_BACKGROUND_COLOR]: "#FFFFFFFF",
  [BASE_INPUT_PLACEHOLDER]: "#000050FF",
  [LABEL_TEXT_COLOR]: "#000000FF",
  [INPUT_GLOW_COLOR]: "#D3185CFF",
  [BASE_INPUT_BACKGROUND]: "#F3F3F3FF",
  [BASE_INPUT_COLOR]: "#000050FF",
  [SHOWROOM_LEFT_SIDEBAR_BACKGROUND_COLOR]: "#FAFBFBFF",
  [SHOWROOM_RIGHT_SIDEBAR_BACKGROUND_COLOR]: "#FAFBFBFF",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DESIGN]: "#ddc9ac",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DESIGN]: "#b76d00",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_LIVE]: "#debac7",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_LIVE]: "#d42665",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DELIVERED]: "#afdbb2",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DELIVERED]: "#23d330",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_REVIEW]: "#adbfe2",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_REVIEW]: "#1a64f0",
  [SHOWROOM_DEVICE_THEME]: "light"
}

export const lightPresets = {
  [PRIMARY_TEXT_COLOR]: "#333333FF",
  [SECONDARY_TEXT_COLOR]: "#666666FF",
  [TERTIARY_TEXT_COLOR]: "#61676e",
  [ACCENT_COLOR]: "#007BFFFF",
  [ACCENT_COLOR_SECONDARY]: "#CCCCCCFF",
  [HEADLINE_COLOR]: "#333333FF",
  [SHOWROOM_HEADER_BACKGROUND_COLOR]: "#FAFBFBFF",
  [SHOWROOM_HEADER_ICON_COLOR]: "#666666FF",
  [SHOWROOM_HEADER_ICON_COLOR_HOVER]: "#000000FF",
  [SVG_STROKE_COLOR]: "#666666FF",
  [SVG_STROKE_COLOR_HOVER]: "#333333FF",
  [PRIMARY_BUTTON_TEXT_COLOR]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR_HOVER]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR_PRESSED]: "#ffffff",
  [PRIMARY_BUTTON_BACKGROUND_DEFAULT]: "#007BFFFF",
  [PRIMARY_BUTTON_BACKGROUND_HOVER]: "#0056B3FF",
  [PRIMARY_BUTTON_BACKGROUND_PRESSED]: "#434D56FF",
  [BUTTON_M_BORDER_RADIUS]: "22px",
  [BUTTON_M_BORDER_RADIUS_HOVER]: "19px",
  [SHOWROOM_MAIN_BACKGROUND_COLOR]: "#F5F5F5FF",
  [DEVICE_SCREEN_BACKGROUND]: "#F5F5F5FF",
  [BUTTON_REFRESH_BORDER_RADIUS]: "22px",
  [BUTTON_REFRESH_BORDER_RADIUS_HOVER]: "22px",
  [SECONDARY_BUTTON_BACKGROUND_DEFAULT]: "#FFFFFFFF",
  [SECONDARY_BUTTON_BACKGROUND_HOVER]: "#000000FF",
  [SECONDARY_BUTTON_BACKGROUND_PRESSED]: "rgba(132, 135, 139, 0.52)",
  [SECONDARY_BUTTON_BACKGROUND_DISABLED]: "rgba(132, 135, 139, 0.08)",
  [SECONDARY_BUTTON_COLOR_DEFAULT]: "#333333FF",
  [SECONDARY_BUTTON_COLOR_ACTIVE]: "#FFFFFFFF",
  [SECONDARY_BUTTON_SHADOW_COLOR]: "#333333FF",
  [TABS_HEADER_TEXT_COLOR]: "#61676e",
  [TABS_HEADER_TEXT_COLOR_ACTIVE]: "#ffffff",
  [CARD_BACKGROUND_COLOR_HOVER]: "#222529",
  [DISPLAY_VARIANTS_COLOR]: "#61676EFF",
  [DISPLAY_VARIANTS_COLOR_ACTIVE]: "#FFFFFFFF",
  [DISPLAY_VARIANTS_COLOR_DISABLED]: "#DEE2E6FF",
  [DISPLAY_VARIANTS_BACKGROUND_ACTIVE]: "#007BFFFF",
  [COLOR_PICKER_BACKGROUND_COLOR]: "#292c31",
  [COLOR_PICKER_BORDER_COLOR]: "#222529FF",
  [SHOWROOM_ACTIVE_OPTION_BACKGROUND_COLOR]: "#007BFFFF",
  [SHOWROOM_ACTIVE_OPTION_COLOR]: "#FFFFFFFF",
  [MODAL_BACKGROUND_COLOR]: "#FFFCF9FF",
  [BASE_INPUT_PLACEHOLDER]: "#333333FF",
  [LABEL_TEXT_COLOR]: "#333333FF",
  [INPUT_GLOW_COLOR]: "#007BFFFF",
  [BASE_INPUT_BACKGROUND]: "#CCCCCCFF",
  [BASE_INPUT_COLOR]: "#333333FF",
  [SHOWROOM_LEFT_SIDEBAR_BACKGROUND_COLOR]: "#FAFBFBFF",
  [SHOWROOM_RIGHT_SIDEBAR_BACKGROUND_COLOR]: "#FAFBFBFF",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DESIGN]: "#ddc9ac",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DESIGN]: "#b76d00",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_LIVE]: "#debac7",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_LIVE]: "#d42665",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DELIVERED]: "#afdbb2",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DELIVERED]: "#23d330",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_REVIEW]: "#adbfe2",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_REVIEW]: "#1a64f0",
  [SHOWROOM_DEVICE_THEME]: "light"
}

export const darkPresets = {
  [PRIMARY_TEXT_COLOR]: "#FFFFFFFF",
  [SECONDARY_TEXT_COLOR]: "#84878bFF",
  [TERTIARY_TEXT_COLOR]: "#61676e",
  [ACCENT_COLOR]: "#d4ff00",
  [ACCENT_COLOR_SECONDARY]: "#34373a",
  [HEADLINE_COLOR]: "#FFFFFFFF",
  [SHOWROOM_HEADER_BACKGROUND_COLOR]: "#222529",
  [SHOWROOM_HEADER_ICON_COLOR]: "#84878b",
  [SHOWROOM_HEADER_ICON_COLOR_HOVER]: "#ffffff",
  [SVG_STROKE_COLOR]: "#84878b",
  [SVG_STROKE_COLOR_HOVER]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR_HOVER]: "#ffffff",
  [PRIMARY_BUTTON_TEXT_COLOR_PRESSED]: "#ffffff",
  [PRIMARY_BUTTON_BACKGROUND_DEFAULT]: "linear-gradient(to bottom,#486ee0, #243dc6 100%)",
  [PRIMARY_BUTTON_BACKGROUND_HOVER]: "linear-gradient(to bottom, #3157cb, #10259a 100%)",
  [PRIMARY_BUTTON_BACKGROUND_PRESSED]: "linear-gradient(to bottom, #133cbb, #0b1b74 100%)",
  [BUTTON_M_BORDER_RADIUS]: "19px",
  [BUTTON_M_BORDER_RADIUS_HOVER]: "19px",
  [SHOWROOM_MAIN_BACKGROUND_COLOR]: "#181a1d",
  [DEVICE_SCREEN_BACKGROUND]: "#343a40",
  [BUTTON_REFRESH_BORDER_RADIUS]: "22px",
  [BUTTON_REFRESH_BORDER_RADIUS_HOVER]: "22px",
  [SECONDARY_BUTTON_BACKGROUND_DEFAULT]: "rgba(132, 135, 139, 0.11)",
  [SECONDARY_BUTTON_BACKGROUND_HOVER]: "rgba(132, 135, 139, 0.2)",
  [SECONDARY_BUTTON_BACKGROUND_PRESSED]: "rgba(132, 135, 139, 0.52)",
  [SECONDARY_BUTTON_BACKGROUND_DISABLED]: "rgba(132, 135, 139, 0.08)",
  [SECONDARY_BUTTON_COLOR_DEFAULT]: "#ffffff",
  [SECONDARY_BUTTON_COLOR_ACTIVE]: "#ffffff",
  [SECONDARY_BUTTON_SHADOW_COLOR]: "#ffffff",
  [TABS_HEADER_TEXT_COLOR]: "#61676e",
  [TABS_HEADER_TEXT_COLOR_ACTIVE]: "#ffffff",
  [CARD_BACKGROUND_COLOR_HOVER]: "#222529",
  [DISPLAY_VARIANTS_COLOR]: "#61676EFF",
  [DISPLAY_VARIANTS_COLOR_ACTIVE]: "#D6DAE0FF",
  [DISPLAY_VARIANTS_COLOR_DISABLED]: "#DEE2E6FF",
  [DISPLAY_VARIANTS_BACKGROUND_ACTIVE]: "#3B3E44FF",
  [COLOR_PICKER_BACKGROUND_COLOR]: "#292c31",
  [COLOR_PICKER_BORDER_COLOR]: "#222529FF",
  [SHOWROOM_ACTIVE_OPTION_BACKGROUND_COLOR]: "#3B3E44FF",
  [SHOWROOM_ACTIVE_OPTION_COLOR]: "#ffffff",
  [MODAL_BACKGROUND_COLOR]: "#222529FF",
  [BASE_INPUT_PLACEHOLDER]: "#52565AFF",
  [LABEL_TEXT_COLOR]: "#B7BAC0FF",
  [INPUT_GLOW_COLOR]: "#A2C100FF",
  [BASE_INPUT_BACKGROUND]: "#0C0D0F",
  [BASE_INPUT_COLOR]: "#ffffff",
  [SHOWROOM_LEFT_SIDEBAR_BACKGROUND_COLOR]: "#222529",
  [SHOWROOM_RIGHT_SIDEBAR_BACKGROUND_COLOR]: "#222529",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DESIGN]: "#223653",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DESIGN]: "#4892ff",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_LIVE]: "#214538",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_LIVE]: "#2bd99a",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_DELIVERED]: "#50244d",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_DELIVERED]: "#dc2ccf",
  [SHOWROOM_CREATIVE_STATUS_BADGE_BACKGROUND_COLOR_REVIEW]: "#52401d",
  [SHOWROOM_CREATIVE_STATUS_BADGE_TEXT_COLOR_REVIEW]: "#e59b0f",
  [SHOWROOM_DEVICE_THEME]: "dark"
}
