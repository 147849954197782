<template>
  <div class="campaign-list-filters">
    <search
      popup-component="campaign-popup"
      @change="search"
    />
    <div
      v-tippy
      class="campaign-list-filters__show-archived"
      :class="{active: showArchivedCampaigns}"
      :content="`${showArchivedCampaigns ? 'Hide' : 'Show'} archived campaigns`"
      @click="toggleShowArchivedCampaigns"
    >
      <icon
        name="archive"
      />
    </div>
    <tag-list class="selected-filters">
      <tag
        v-for="(filter, index) in mappedSelectedFilters"
        :key="index"
      >
        {{ filter.name }} <a
          href="#"
          @click.prevent="removeFilter(filter.type, filter.value)"
        ><icon name="close" /></a>
      </tag>
    </tag-list>
    <input-dropdown
      class="without-highlight ml-auto"
      :allow-empty="false"
      :options="orderByOptions"
      placeholder="Select sort order"
      :preselect-first="true"
      :searchable="false"
      :show-labels="false"
      :min-width="200"
      :track-by="null"
      :max-width="200"
      :value="orderByOptions.find(v => v.value === orderBy)"
      @input="updateOrderBy"
    >
      <template #single-option="{ option }">
        {{ option.name }}
      </template>
      <template #option-row="{ option }">
        {{ option.name }}
      </template>
    </input-dropdown>
  </div>
</template>

<script>
import Search from "../common/search/Search"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: "CampaignsListFilters",
  components: {
    Search
  },
  props: {
    orderBy: {
      type: String,
      default: "last_edit"
    }
  },
  emits: ["show-archived-updated", "update-order-by"],
  data () {
    return {
      orderByOptions: [
        { name: "Last updated", value: "last_edit" },
        { name: "Created at", value: "created_at" }
      ]
    }
  },
  computed: {
    ...mapState(["selectedFilters", "currentUser"]),
    mappedSelectedFilters () {
      const filters = []
      this.selectedFilters.campaign.forEach((campaign) => filters.push({ type: "campaign", value: campaign.value, name: campaign.name }))
      this.selectedFilters.tag.forEach((tag) => filters.push({ type: "tag", value: tag.value, name: tag.name }))
      this.selectedFilters.brand.forEach((brand) => filters.push({ type: "brand", value: brand.value, name: brand.name }))
      this.selectedFilters.status.forEach((status) => filters.push({ type: "status", value: status.value, name: status.name }))

      return filters
    },
    showArchivedCampaigns: {
      get () {
        return Boolean(this.currentUser.settings.showArchived)
      },
      set (val) {
        this.setUserWorkspaceSettings({ showArchived: val })
        this.updateUserSettings({ showArchived: val })
        this.$emit("show-archived-updated", val)
      }
    }
  },
  methods: {
    ...mapMutations(["setUserWorkspaceSettings", "deleteFilter"]),
    ...mapActions(["loadSearchResult", "updateUserSettings"]),
    search (value) {
      this.loadSearchResult({ query: value })
    },
    removeFilter (type, value) {
      this.deleteFilter({ type, value })
    },
    toggleShowArchivedCampaigns () {
      this.showArchivedCampaigns = !this.showArchivedCampaigns
    },
    updateOrderBy (value) {
      this.$emit("update-order-by", value)
    }
  }
}
</script>
