<template>
  <span v-if="text">
    <span
      v-if="isTruncated"
      v-tippy="tooltipConfig"
      :content="text"
      class="truncated-text"
    >{{ truncatedText }}</span>
    <span v-else>{{ text }}</span>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    length: {
      type: Number,
      default: 16
    },
    tooltipMaxWidth: {
      type: Number,
      default: 350
    },
    tooltipPlacement: {
      type: String,
      default: "top"
    }
  },
  computed: {
    isTruncated () {
      return this.text.length >= this.length
    },
    truncatedText () {
      return this.text.substr(0, this.length) + (this.isTruncated ? "..." : "")
    },
    tooltipConfig () {
      return {
        maxWidth: this.tooltipMaxWidth,
        placement: this.tooltipPlacement
      }
    }
  }
}
</script>

<style scoped>
.truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
</style>
