export default {

  // SHOWROOM
  activeShowroomTheme: "dark",

  hideEditor: false,
  // COLORS
  savedColors: [],
  defaultPalette: [
    "#d4ff00",
    "#D0021B",
    "#F5A623",
    "#F8E71C",
    "#8B572A",
    "#7ED321",
    "#417505",
    "#BD10E0",
    "#9013FE",
    "#4A90E2",
    "#50E3C2",
    "#B8E986",
    "#000000",
    "#4A4A4A",
    "#9B9B9B",
    "#FFFFFF",
    "#00000000"
  ],

  // DESIGNER
  activeTimelineTab: null,
  timelineFocused: false,
  activeScene: "", // Active scene uuid
  activeTimelineBlock: null,
  activeTimelineBlocks: [],
  guidelines: {
    horizontal: [],
    vertical: [],
    horizontalLocked: [],
    verticalLocked: []
  },
  recordingTime: -1,
  recordingModule: null,
  timelinePlaying: false,
  editingCustomAnimation: null,
  draftEvent: {
    action: {},
    actionTarget: null,
    conditions: [],
    delay: null,
    target: "",
    trigger: null,
    uuid: null
  },
  firstStateSaved: false,
  lastSavedStateHash: "",
  collapsedTimelineLayers: [],
  collapsedLayers: [],
  renamingInputActive: false,
  layerFolderIndex: 0,
  timelineSettingsCollapsed: true,
  layerInputCalls: 0,
  dataBinding: [], // Contains CURRENT user-defined variables (available in current scope),
  previewDataBinding: null,
  timelineScaleY: 1.0,
  timelineScaleX: 1.0,
  timelineOffset: 0,
  designerZoom: 1,
  draggedLayer: false,
  assetUploadCallback: null,
  deletedAssetsId: [],
  assets: [],
  currentTime: 0,
  selectedModule: null, // used to determine last actively selected module
  currentScope: null, // used in SwiperGroupModule child element add,
  shiftPressed: false,
  ctrlPressed: false,
  draggedModule: null,
  draggedAsset: null,
  currentState: null,
  stateId: null,
  locked: false,
  lockedBy: {},
  blockScreenshot: false,
  replaceAssetUuid: null,
  scenes: [],
  modules: [],
  preventedEvents: [], // Array with objects: {eventName: string, check: func}, where the check function returns true/false
  currentDirectoryLevel: "/",
  currentAssetsTree: {},
  availableDimensions: {
    landscape: "Landscape",
    portrait: "Portrait"
    // "both": "Both"
  },
  availableDeviceTypes: {
    phone: "Mobile",
    tablet: "Tablet",
    desktop: "Desktop"
  },
  events: [],

  // ANALYTICS
  analytics: {
    startDate: null,
    endDate: null,
    campaignAnalytics: {
      // Fetched dynamically from APIs
      displays: 0,
      clicks: 0,
      clickrate: 0,
      users: 0
    },
    currentAnalytics: {
      // Fetched dynamically from APIs
      displays: 0,
      clicks: 0,
      clickrate: 0,
      users: 0
    },
    analyticsFetching: false
  },

  // FEATURES
  availableFeatures: [],
  allFeatures: [],

  // REST
  roles: ["user"],
  groups: [],
  users: [],
  reports: [],
  templates: [],
  designs: [],
  adtags: [],
  hostedDesigns: [],
  invoices: [],
  campaigns: [],
  billables: [],
  liveCampaigns: [],
  hostedCampaigns: [],
  companies: [],
  comments: [],
  name: "",
  packageId: "",
  loading: true,
  loadingStatus: false, // Loading status of uploaded assets
  cropImgData: {},
  activeGroup: null,
  activeDesign: null,
  activeInvoice: null,
  activeCampaign: null,
  activeCompany: null,
  activeCampaignId: null,
  activeCompanyId: null,
  activeTemplateId: null,
  currentUser: null,
  skipNavigationGuard: false,
  settingsContext: "", // manipulate for changing context of edited settings
  settingsObject: {},
  searchResult: {},
  selectedFilters: {
    role: [],
    campaign: [],
    tag: [],
    status: [],
    brand: [],
    user: [],
    group: []
  },
  notifications: [],
  availableRoles: [
    {
      name: "Super Admin",
      value: "super_admin"
    },
    {
      name: "Admin",
      value: "admin"
    },
    {
      name: "Manager",
      value: "manager"
    },
    {
      name: "Designer",
      value: "user"
    }
  ],
  availableCountries: [{
    title: "Germany",
    value: "DE"
  }],
  availableLanguages: [{
    title: "English",
    value: "en"
  }],
  selectedTag: null,
  activeUser: null,
  availableBrands: [],
  selectedDashboard: "designer",
  selectedShowroomSettingsEditorMode: "basic",
  permissions: {
    create: false,
    view: false,
    edit: false,
    publish: false,
    delete: false
  },
  companyTags: [],
  showResolvedComments: false,
  currentlyEditedCommentUuid: null,

  // MODAL
  modal: {
    isOpen: false,
    view: {},
    actions: []
  },

  activeDomain: null,
  domainLimitedAccess: false,
  buildNodeStatus: {
    connected: false,
    count: 0
  }
}
