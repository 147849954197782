export class Routes {
  // DELETE
  static deleteDesign = (designUuid) => `/api/design/${designUuid}`
  static deleteCampaignTag = (tag) => `/api/campaign/tag/${tag}`

  // GET
  static getDesignResource = (designUuid) => `/api/design/${designUuid}`
  static getAvailableModules = (designUuid) => `/api/design/${designUuid}/available-module`
  static convertAdToExternal = (designUuid) => `/api/design/${designUuid}/convert-external`
  static unhostDesign = (designUuid) => `/api/design/${designUuid}/unhost`
  static fetchDesignFromRepository = (designUuid) => `/api/design/${designUuid}/fetch`
  static downloadPackage = (designUuid) => `/api/design/${designUuid}/download-package`
  static downloadPackageWithId = (designUuid, packageId) => `/api/design/${designUuid}/download/${packageId}`
  static duplicateDesign = (designUuid) => `/api/design/${designUuid}/duplicate`
  static buildDesign = (designUuid) => `/api/design/${designUuid}/build`
  static queueDesignSize = (designUuid) => `/api/design/${designUuid}/queue_size`
  static getDesignSize = (designUuid) => `/api/design/${designUuid}/size`
  static getCampaignResources = (campaignUuid) => `/api/design/${campaignUuid}`
  static downloadAssets = (designUuid) => `/api/design/${designUuid}/download-assets`
  static downloadAssetsDirectory = (designUuid, path) => `/api/design/${designUuid}/download-assets-directory?directory=${encodeURIComponent(path)}`
  static downloadAssetsAsset = (designUuid, assetUuid) => `/api/design/${designUuid}/download-assets/${encodeURIComponent(assetUuid)}`

  // POST
  static shareDesignLink = (designUuid, activeDomain) => `/api/design/${designUuid}/share?activeDomain=${activeDomain}`
  static createDesign = () => "/api/design"
  static regenerateToken = (designUuid) => `/api/design/${designUuid}/regenerate-token`
  static archiveDesign = (designUuid) => `/api/design/${designUuid}/archive`
  static unarchiveDesign = (designUuid) => `/api/design/${designUuid}/unarchive`
  static pushDesignToRepository = (designUuid) => `/api/design/${designUuid}/push`
  static initDesignTag = (designUuid) => `/api/design/${designUuid}/init-tags`
  static hostDesign = (designUuid) => `/api/design/${designUuid}/host`
  static approveDesign = (designUuid) => `/api/design/${designUuid}/approve`
  static reviewDesign = (designUuid) => `/api/design/${designUuid}/review`
  static rejectDesign = (designUuid) => `/api/design/${designUuid}/reject`
  static queueDesignScreenshot = (designUuid) => `/api/design/${designUuid}/screenshot/queue`
  static saveDesignScreenshot = (designUuid) => `/api/design/${designUuid}/screenshot/save`
  static queueDesignPackingJob = (designUuid, job) => `/api/design/${designUuid}/pack/${job}`
  static takeoverDesign = (designUuid) => `/api/design/${designUuid}/takeover`
  static setDesignHeartBeat = (designUuid) => `/api/design/${designUuid}/heartbeat`

  // PUT
  static updateDesign = (designUuid) => `/api/design/${designUuid}`
  static updateDesignDetails = (designUuid) => `/api/design/${designUuid}/detail`
  static updateDesignName = (designUuid) => `/api/design/${designUuid}/name`
}
