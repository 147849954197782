import Echo from "laravel-echo"
import Pusher from "pusher-js"

window.Pusher = Pusher
window.Pusher.Runtime.createXHR = function () {
  const xhr = new XMLHttpRequest()
  xhr.withCredentials = true
  return xhr
}

window.Echo = new Echo({
  broadcaster: "reverb",
  key: process.env.VUE_APP_REVERB_APP_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST,
  wsPort: process.env.VUE_APP_REVERB_PORT,
  wssPort: process.env.VUE_APP_REVERB_PORT,
  forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? "https") === "https",
  enabledTransports: ["ws", "wss"]
})
