<template>
  <template v-if="Utils.getPageLayout() === 'clean'">
    <router-view />
  </template>
  <template v-if="Utils.getPageLayout() === 'spa'">
    <div class="main layouts-spa">
      <router-view />
    </div>
  </template>
  <modal-container />
</template>

<script>
import Utils from "./js/utils"
import ModalContainer from "./js/components/common/modal/ModalContainer.vue"

export default {
  name: "App",
  components: {
    ModalContainer
  },
  computed: {
    Utils () {
      return Utils
    }
  },
  created () {
    const regexSafe = /(\/invites\/(.*?)|\/password\/reset|\/password\/recovery|\/login|\/showroom(.*?))/i
    const regex = /(\/adtags(.*))/i

    if (regex.test(window.location.pathname) || !regexSafe.test(window.location.pathname)) {
      this.$store.dispatch("loadCurrentUser").then((userData) => {
        this.$store.dispatch("loadAvailableFeatures", userData.active_company.uuid)
      }).catch(() => {
        console.error("Error loading current user")
      })
    }
  },
  beforeUnmount () {
    window.intercomTourObserver?.disconnect()
  }
}
</script>

<style lang="scss">
@import './sass/app.scss';
</style>

<style>

:root {
  --grey_1: #181a1d;
  --grey_2: #222529;
  --grey_3: #292c31;
  --grey_5: #3b3e44;
  --grey_6: #41454a;
  --grey_7: #52565a;
  --grey_8: #61676e;
  --grey_9: #84878b;
  --grey_10: #b7bac0;
  --grey_12: #d6dae0;
  --grey_800:#343a40;
  --white: #ffffff;
  --green: #d4ff00;
  --green_2: #a2c100;
  --blue-two: #486ee0;
  --shade21:#393939;
  --shade16:#262626;
  --primary_black:#0c0d0f;
  --primary_blue:#243dc6;

  --scrollbar-bg-color: transparent;
  --scrollbar-thumb-color: var(--grey_6);
  --scrollbar-thumb-hover: var(--grey_7);

  --bg-color: var(--grey_1);

  --text-color: hsla(0,0%,100%,0.7);

  --primary-button-text-color: var(--white);
  --primary-button-text-color-hover: var(--white);
  --primary-button-text-color-pressed: var(--white);
}

 .theme--dark {
   /* Toasts */
   --toast-background: var(--showroom-left-sidebar-background-color);
   --toast-title-text-color: var(--primary-text-color);
   --toast-content-text-color: var(--secondary-text-color);

   /* Basic */
   --primary-text-color: var(--white);
   --secondary-text-color: var(--grey_9);
   --tertiary-text-color: var(--grey_8);

   --accent-color: var(--green);
   --accent-color-secondary: #34373a;

   --headline-color: var(--white);

   /* Showroom header */
   --showroom-header-background-color: var(--grey_2);
   --showroom-header-icon-color: var(--grey_9);
   --showroom-header-icon-color-hover: var(--white);

   --svg-stroke-color: var(--grey_9);
   --svg-stroke-color-hover: var(--white);

   /* Share button */
   --primary-button-background-default: linear-gradient(to bottom, var(--blue-two), var(--primary_blue) 100%);
   --primary-button-background-hover: linear-gradient(to bottom, #3157cb, #10259a 100%);
   --primary-button-background-pressed: linear-gradient(to bottom, #133cbb, #0b1b74 100%);
   --primary-button-text-color: var(--white);
   --primary-button-text-color-hover: var(--white);
   --primary-button-text-color-pressed: var(--white);

   --button-m-border-radius: 19px;

   /* Showroom main */
   --showroom-main-background-color: var(--bg-color);
   --device-screen-background: var(--grey_800);

   /* Refresh button */
   --button-refresh-border-radius: 22px;

   /* Showroom left sidebar */
   --showroom-left-sidebar-background-color: var(--grey_2);

   /* Go to adtag button */
   --secondary-button-background-default:rgba(132, 135, 139, 0.11);
   --secondary-button-background-hover: rgba(132, 135, 139, 0.2);
   --secondary-button-background-pressed: rgba(132, 135, 139, 0.52);
   --secondary-button-background-disabled:  rgba(132, 135, 139, 0.08);
   --secondary-button-color-default: var(--white);
   --secondary-button-color-active: var(--white);
   --secondary-button-text-color-pressed: var(--white);
   --secondary-button-shadow-color: var(--white);

   /* Showroom right sidebar */
   --showroom-right-sidebar-background-color: var(--grey_2);
   --tabs-header-text-color: var(--grey_8);
   --tabs-header-text-color-active: var(--white);

   --card-background-color: var(--showroom-right-sidebar-background-color);
   --card-background-color-hover: #222428;
   --display-variants-color: var(--secondary-text-color);
   --display-variants-color-active: var(--white);
   --display-variants-color-disabled: var(--display-variants-color);
   --display-variants-background-active: var(--grey_5);

   --color-picker-background-color: #292c31;
   --color-picker-border-color: var(--grey_2);

   --showroom-active-option-background-color: var(--grey_5);
   --showroom-active-option-color: var(--white);

   /* Showroom share modal */
   --modal-background-color: var(--grey_2);
   --label-text-color: var(--grey_10);
   --base-input-background: var(--primary_black);
   --base-input-color: #ffffff;
   --base-input-placeholder: var(--grey_7);
   --input-glow-color: var(--green_2);

   /* Creative status badge */
  --showroom-creative-status-badge-background-color-design: #223653;
  --showroom-creative-status-badge-text-color-design: #4892ff;

   --showroom-creative-status-badge-background-color-live: #214538;
   --showroom-creative-status-badge-text-color-live: #2bd99a;

   --showroom-creative-status-badge-background-color-delivered: #50244d;
   --showroom-creative-status-badge-text-color-delivered: #dc2ccf;

   --showroom-creative-status-badge-background-color-review: #52401d;
   --showroom-creative-status-badge-text-color-review: #e59b0f;

 }
</style>
