<template>
  <li
    :key="design.uuid"
    :class="['design-list__element', design.archived ? 'archived':'']"
  >
    <div class="avatar-wrapper creator">
      <avatar
        v-if="design.user"
        :alt="[design.user.firstname, design.user.lastname].join(' ')"
        :avatar="design.user.avatar"
        :size="30"
        :title="[design.user.firstname, design.user.lastname].join(' ')"
        :tooltip="true"
        :user-id="design.user.uuid"
      />
    </div>
    <div class="design">
      <div class="design__thumbnail">
        <img
          :alt="design.name + ' thumbnail'"
          :class="preview === null ? 'missing-thumbnail' : 'found-thumbnail'"
          :src="preview !== null ? preview + Date.now() : require('../../../images/design/design-fallback.svg')"
          @error="missingThumbnail(design.uuid)"
        />
      </div>
      <design-row
        :design="design"
        :show-date="false"
        :show-details="true"
        :show-id="true"
        :show-link="design.creation_type === DESIGN_CREATION_TYPE_ADTRON && !design.archived"
      />
    </div>
    <div class="last-edit">
      <span class="status__header">last edit</span>
      <div class="avatars-stacked">
        <div
          v-for="(editor, index) in getFilteredEditors(design.editors)"
          :key="`editor-${editor.uuid}`"
          class="avatar-wrapper"
        >
          <avatar
            :alt="[editor.firstname, editor.lastname].join(' ')"
            :avatar="editor.avatar"
            :class="{active: design.locked && design.locked_by.uuid === editor.uuid}"
            :size="index === getFilteredEditors(design.editors).length -1 ? 38 : 26"
            :title="[editor.firstname, editor.lastname].join(' ')"
            :tooltip="true"
            :tooltip-content="'Last edit by ' + [editor.firstname, editor.lastname].join(' ') + (design.last_edit ? ' on ' + Utils.parseDate(design.last_edit) : '')"
            :user-id="editor.uuid"
          />
        </div>
      </div>
    </div>
    <div class="status">
      <span class="status__header">status</span>
      <design-status :status="design.progress" />
    </div>
    <div class="actions">
      <div class="action-list inline">
        <component
          :is="action.routerLink ? 'router-link' : 'a'"
          v-for="action in rowActions"
          :key="`${design.uuid}-${action.content}`"
          v-tippy
          class="item"
          :class="{'disabled': Boolean(action.disabled)}"
          :content="action.content"
          :href="action.href"
          :to="action.href"
          @click.prevent="action.click"
        >
          <icon
            :disabled="Boolean(action.disabled)"
            :name="action.icon"
          />
        </component>
      </div>
    </div>
  </li>
</template>

<script>
import DesignStatus from "../common/design/DesignStatus"
import { DESIGN_CREATION_TYPE_ADTRON } from "@/constants"
import Utils from "../../utils"

export default {
  name: "DesignListItem",
  components: {
    DesignStatus
  },
  props: {
    design: {
      type: Object,
      required: true
    },
    rowActions: {
      type: Array,
      required: true
    },
    previews: {
      type: Array,
      required: true
    }
  },
  emits: ["missing-thumbnail"],
  computed: {
    preview () {
      const preview = this.previews.find(p => p.designUuid === this.design.uuid)
      if (preview) {
        return preview.screenshot
      }
      return null
    },
    DESIGN_CREATION_TYPE_ADTRON () {
      return DESIGN_CREATION_TYPE_ADTRON
    },
    Utils () {
      return Utils
    }
  },
  methods: {
    missingThumbnail (designUuid) {
      this.$emit("missing-thumbnail", designUuid)
    },
    getFilteredEditors (editors) {
      return editors.filter(editor => Boolean(editor) === true)
    }
  }
}
</script>
